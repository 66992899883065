import React from 'react'
import {css} from '@emotion/core'
import theme from '../../config/theme'
import {bpMaxMD, bpMaxSM} from '../lib/breakpoints'
import {rhythm, fonts} from '../lib/typography'
import Markdown from 'react-markdown'
import Container from 'components/container'

function Hero({
  children,
  title = 'Hello, World! I’m James — A lifelong learner',
  text,
  ...props
}) {
  return (
    <section
      css={css`
        width: 100%;
        color: ${theme.colors.white};
        background: ${theme.colors.ac};
        padding-top: 40px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 0;

        ${bpMaxMD} {
          padding-top: 60px;
        }
        ${bpMaxSM} {
          padding-top: 60px;
        }
      `}
      {...props}
    >
      <Container
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-bottom: 0;

          ${bpMaxMD} {
            flex-direction: column;
            align-items: center;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%; // Adjusted for full width in the absence of images
          `}
        >
          <p
            css={css`
              margin-top: 10px;
              margin-bottom: 10px;
              max-width: ${rhythm(17)};
              font-size: 18px;
              font-family: ${fonts.regular}, sans-serif;
            `}
          >
            {title}
          </p>
          {text && (
            <Markdown
              css={css`
                max-width: 400px;
                p {
                  font-family: ${fonts.light};
                }
                a {
                  text-decoration: underline;
                  :hover {
                    color: hsla(255, 100%, 100%, 0.9);
                  }
                }
              `}
            >
              {text}
            </Markdown>
          )}
          {children}
        </div>
      </Container>
    </section>
  )
}

export default Hero
